import React, { useEffect, useState } from 'react';
import useDetectionStore from '@/Models/useDetectionStore';
import GestureKeyboard from '@/Xomponents/GestureKeyboard';
import FullScreenButton from './FullScreenButton/FullScreenButton';
import LandscapeMessage from '@/Xomponents/LandscapeMessage';
import Speak from '@/Xomponents/GestureFlag/Speak/Speak';
import LogoutButton from '@/Xomponents/LogoutButton/LogoutButton';
import IUser from '@/Models/Types';
import Loader from '@/Xomponents/Loader/Loader';
// import WordSuggestion from '@/Xomponents/WordSuggestion/WordSuggestion';
import './Home.css';
import PhraseContainer from '@/Xomponents/PhraseContainer/PhraseContainer';
import GestureCheck from '@/Xomponents/Detector/GestureCheck/GestureCheck';
import EventsBar from '@/Xomponents/EventsBar/EventsBar';
// import Suggest from '@/Xomponents/GestureFlag/Suggest/Suggest';

interface HomeProps {
  setCurrentUser: (user: IUser | null) => void;
}

const Home: React.FC<HomeProps> = ({ setCurrentUser }) => {
  const {
    faceLandMark: { complete },
  } = useDetectionStore();
  const [isLandscape, setIsLandscape] = useState<boolean>(window.innerWidth > window.innerHeight);

  useEffect(() => {
    const checkOrientation = () => {
      const isLandscapeNow = window.innerWidth > window.innerHeight;
      console.log('Is Landscape: ', isLandscapeNow);
      setIsLandscape(isLandscapeNow);
    };
    window.addEventListener('resize', checkOrientation);
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, [complete]);

  return (
    <div className="home-container" style={{ position: 'relative' }}>
      {!complete && <Loader />}
      {!isLandscape && <LandscapeMessage />}

      <div id="homeMain" style={{ display: isLandscape ? 'flex' : 'none' }}>
        <div className="home-buttons">
          <FullScreenButton />
          <Speak />
          <LogoutButton setCurrentUser={setCurrentUser} />
        </div>
        <PhraseContainer />
        <GestureKeyboard />
        {/* <EventsBar /> */}

        {/* <WordSuggestion /> */}

        {/* <Suggest /> */}
        <div style={{ width: 'px', outline: '2px solid rgba(255, 255, 255, 0.3)', height: '1vh' }}></div>
      </div>
    </div>
  );
};

export default Home;
