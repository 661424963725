import { create } from 'zustand';
import { LEFT_COMMANDS, RIGHT_COMMANDS } from './useGestureKeyboardStore';

interface WordSuggestionState {
  suggestions: string[];
  showSuggestions: boolean;
  isFocused: boolean;
  left: {
    keys: string[];
    commands: string[];
  };
  right: {
    keys: string[];
    commands: string[];
  };
  setSuggestions: (suggestions: string[]) => void;
  pick: (phrases: string[], commands: string[]) => void;
  setShowSuggestions: (showSuggestions: boolean) => void;
  setIsFocused: (showKeyboard: boolean) => void;
}

const MOCK_PHRASES = ['una sugerencia ', 'otra sugerencia ', 'otra sugerencia mas'];

/**
 * Determina las teclas y comandos de la mitad izquierda.
 * @param keys - Lista de teclas.
 * @param commands - Lista de comandos.
 * @returns Un objeto con las teclas y comandos de la mitad izquierda.
 */
const resolveLeft = (keys: string[], commands: string[]) => {
  const out: {
    keys: string[];
    commands: string[];
  } = {
    keys: [],
    commands: [],
  };

  // si hay una tecla y no hay comandos
  if (keys.length === 1 && commands.length === 0) {
    out.keys = MOCK_PHRASES.slice(0, MOCK_PHRASES.length / 2);
    out.commands = [...LEFT_COMMANDS];
    // si hay una tecla y un comando
  } else if (keys.length === 1 && commands.length === 1) {
    out.keys = keys;
    out.commands = [];
    // si hay una tecla y mas de un comando
  } else if (keys.length === 1 && commands.length > 1) {
    out.keys = keys;
    out.commands = [];
    // si no hay teclas y un comando
  } else if (keys.length == 0 && commands.length == 1) {
    out.keys = MOCK_PHRASES.slice(0, MOCK_PHRASES.length / 2);
    out.commands = [...LEFT_COMMANDS];
    // si no hay teclas y mas de un comando
  } else if (keys.length == 0 && commands.length > 1) {
    out.keys = [];
    out.commands = commands.slice(0, commands.length / 2);
    // si hay mas de una tecla y no hay comandos
  } else {
    out.keys = keys.slice(0, keys.length / 2);
    out.commands = [...LEFT_COMMANDS];
  }

  return out;
};

/**
 * Determina las teclas y comandos de la mitad derecha.
 * @param keys - Lista de teclas.
 * @param commands - Lista de comandos.
 * @returns Un objeto con las teclas y comandos de la mitad derecha.
 */
const resolveRight = (keys: string[], commands: string[]) => {
  const out: {
    keys: string[];
    commands: string[];
  } = {
    keys: [],
    commands: [],
  };

  // si hay una tecla y no hay comandos
  if (keys.length === 1 && commands.length === 0) {
    out.keys = MOCK_PHRASES.slice(MOCK_PHRASES.length / 2);
    out.commands = [...RIGHT_COMMANDS];
    // si hay una tecla y un comando
  } else if (keys.length === 1 && commands.length === 1) {
    out.keys = [];
    out.commands = commands;
    // si hay una tecla y mas de un comando
  } else if (keys.length === 1 && commands.length > 1) {
    out.keys = [];
    out.commands = commands;
    // si no hay teclas y un comando
  } else if (keys.length == 0 && commands.length == 1) {
    out.keys = MOCK_PHRASES.slice(MOCK_PHRASES.length / 2);
    out.commands = [...RIGHT_COMMANDS];
    // si no hay teclas y mas de un comando
  } else if (keys.length == 0 && commands.length > 1) {
    out.keys = [];
    out.commands = commands.slice(commands.length / 2);
    // si hay mas de una tecla y no hay comandos
  } else {
    out.keys = keys.slice(keys.length / 2);
    out.commands = [...RIGHT_COMMANDS];
  }

  return out;
};

export const useWordSuggestionStore = create<WordSuggestionState>(set => ({
  currentPhrase: '',
  suggestions: [...MOCK_PHRASES],
  showSuggestions: false,
  isFocused: false,
  left: {
    keys: [MOCK_PHRASES[0], MOCK_PHRASES[1]],
    commands: [],
  },

  right: {
    keys: [MOCK_PHRASES[2]],
    commands: [],
  },

  // setShowSuggestions: (showSuggestions: boolean) => set({ showSuggestions }),
  setShowSuggestions: (showSuggestions: boolean) => set({ showSuggestions: false }),

  setIsFocused: (isFocused: boolean) => set({ isFocused }),
  setSuggestions: suggestions => set({ suggestions }),

  pick: (phrases: string[], commands: string[]) => {
    set(state => {
      return {
        ...state,
        left: resolveLeft(phrases, commands),
        right: resolveRight(phrases, commands),
      };
    });
  },
}));
